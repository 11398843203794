import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout';
import SiteText from '../components/sitetext';
import ContactForm from '../components/contactform';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery{
        file(relativePath: { eq: "background.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `);


    const backgroundFluid = [
      data.file.childImageSharp.fluid,
        `linear-gradient(rgba(25,25,26,0.73), rgba(25,25,26,0.73))`,

      ].reverse()


  return(
  <>
    <BackgroundImage
      Tag="section"
      fluid={backgroundFluid}
      backgroundColor={`#040e18`}
    >
    <Layout title="Kontakt">
      <SiteText>
      <div id="mainTextContact">
        <h1>Kontakt</h1>

          <p>
            {'Hier können Sie uns eine Nachricht zukommen lassen. Nachdem Ihre Nachricht bei '}
            {'uns eingegangen ist, werden wir uns so schnell wie möglich um Ihr Anliegen kümmern.'}
          </p>
          <br />
          <ContactForm />
        </div>
      </SiteText>
    </Layout>
    </BackgroundImage>
  </>
);
};

export default Contact;
