import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles({
  root: {
    '& label': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: '#b02828',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    'label + &': {
      color: '#ffffff',
    },
  },
})(TextField);


const ContactForm = () => (
  <>

    <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
      <StyledTextField
        required
        color="primary"
        id="name"
        name="name"
        label="Name"
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <br />
      <StyledTextField
        required
        color="primary"
        id="email"
        name="email"
        type="email"
        label="E-Mail"
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <br />
      <StyledTextField
        required
        color="primary"
        id="message"
        name="message"
        label="Nachricht"
        margin="normal"
        multiline
        rows="4"
        variant="outlined"
        fullWidth
      />
      <br />
      <input type="hidden" name="form-name" value="contact" />
      <br />
      <center>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
       Formular absenden
        </Button>
        {'      '}
        <Button
          type="reset"
          variant="contained"
          color="secondary"
        >
       Formular zurücksetzen
        </Button>
      </center>
    </form>
  </>
);
export default ContactForm;
